import PricesSaga from "./sagas";
import PricesReducer from "./reducers";
import pricesActions from "./actions";

export const pricesReducer = {
  pricesReducer: PricesReducer,
};

export const pricesSaga = [PricesSaga()];

export { pricesActions };
