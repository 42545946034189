const actions = {
  FETCH_STUDENT_PAYMENTS_STATUS: "FETCH_STUDENT_PAYMENTS_STATUS",
  FETCH_STUDENT_PAYMENTS_STATUS_SUCCESS:
    "FETCH_STUDENT_PAYMENTS_STATUS_SUCCESS",

  fetchStudentPaymentStatus: (
    // page: number,
    // pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void,
    user_id: any,
  ) => ({
    type: actions.FETCH_STUDENT_PAYMENTS_STATUS,
    // page,
    // pageSize,
    resolve,
    reject,
    user_id
  }),
};

export default actions;
