import { CSSProperties, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { MainLayoutProps } from "../interface/MainLayout.interface";
import {
  Avatar,
  Badge,
  Breadcrumb,
  Col,
  Dropdown,
  Layout,
  MenuProps,
  Row,
  Space,
  theme,
} from "antd";
import { Header, Content } from "antd/es/layout/layout";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import logo from "assets/images/Logo.png";
import sloganlogo from "assets/images/sloganlogo.png"
import actions from "pages/login/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../index.css";
import { NotificationIcon } from "assets/icons/NotificationIcon";
import { AButton, ATitle } from "components";
import { notificationActions } from "pages/notifications/store";
import { IRootState } from "store/interface/Store.interface";
import { NotificationProps } from "pages/notifications";
import { tokens } from "utils/Constants";
import {
  getFormattedBranchAddress,
  getFormattedFullName,
  getFormattedRoleName,
  isOnMobile,
} from "utils/Helpers";
import Footer from "components/Footer";
import { ChatIcon } from "assets";
import chatActions from "../store/actions";

const MainLayout = ({ children }: MainLayoutProps): JSX.Element => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(isOnMobile());
  const [onMobile, setOnMobile] = useState<boolean>(isOnMobile());

  const checkOnMobile = () => {
    setOnMobile(isOnMobile());
    if (!isOnMobile()) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkOnMobile);
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formattedFullName = getFormattedFullName();
  const formattedRoleName = getFormattedRoleName();
  const formattedBranchAddress = getFormattedBranchAddress();

  const notifications = useSelector((state: IRootState) =>
    state.notificationReducer.get("notifications")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(notificationActions.fetchNotifications(resolve, reject));
    });
  }, []);

  const items: MenuProps["items"] = [
    {
      label: "Settings",
      key: "0",
      onClick: () => {
        navigate("settings");
      },
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "1",
      onClick: () => {
        new Promise((resolve, reject) => {
          navigate("/login");
          dispatch(actions.logout(resolve, reject));
        });
      },
    },
  ];

  const location = useLocation();

  // Get the last part of the path (the current page)
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const lastPart = pathSnippets[pathSnippets.length - 1];
  if (!isNaN(Number(lastPart))) {
    pathSnippets.pop();
  }

  const breadcrumbNameMap: Record<string, string> = {
    "/evaluation-test-requests": "Enrollment Requests",
    "/enrollment": "Enrollment",
    "/enrolledStudents": "Enrolled Students",
    "/enrolledStudents/details": "Student Details",
    "/course": "Full Time Course",
    "/course/add": "Add Course",
    "/course/edit": "Edit Course",
    "/course/details": "Course Details",
    "/instructor": "Instructor",
    "/instructor/add": "Add Instructor",
    "/instructor/edit": "Edit Instructor",
    "/instructor/details": "Instructor Details",
    "/branch": "Branch",
    "/branch/add": "Add Branch",
    "/branch/edit": "Edit Branch",
    "/batch": "Batch",
    "/batch/add": "Add Batch",
    "/batch/edit": "Edit Batch",
    "/batch/details": "Batch Details",
    "/timeSlot": "Time Slot",
    "/learning-materials": "Learning Materials",
    "/learning-materials/upload": "Upload",
    "/attendance": "Attendance",
    "/leaveApproval": "Leave Requests",
    "/removeChildApproval": "Remove Child Requests",
    "/chat": "Chat",
    "/newsAndEvents": "News and Events",
    "/newsAndEvents/eventsDetails": "Event Details",
    "/newsAndEvents/eventsAdd": "Add Event",
    "/newsAndEvents/eventsEdit": "Edit Event",
    "/newsAndEvents/news&eventsAdd": "Add News / Events",
    "/newsAndEvents/EditEvent": "Edit Events",
    "/newsAndEvents/EditNews": "Edit News",
    "/newsAndEvents/viewAllNews": "News",
    "/newsAndEvents/viewAllEvents": "Events",
    "/branchAdmin": "Branch Admin",
    "/branchAdmin/add": "Add Branch Admin",
    "/branchAdmin/edit": "Edit Branch Admin",
    "/branchAdmin/details": "Branch Admin Details",
    "/branchAssociate": "Branch Associate",
    "/branchAssociate/add": "Add Branch Associate",
    "/branchAssociate/edit": "Edit Branch Associate",
    "/branchAssociate/details": "Branch Associate Details",
    "/superAdmin": "Super Admin",
    "/superAdmin/add": "Add Super Admin",
    "/superAdmin/edit": "Edit Super Admin",
    "/coupons": "Coupon",
    "/coupons/add": "Add Coupon",
    "/bilingandpayments": "Billing & Payments",
    "/logs": "Logs",
    "/branchSelfService": "Branch Self Service",
    "/branchSelfService/add": "Add Branch Self Service",
    "/branchSelfService/edit": "Edit Branch Self Service",
    "/branchSelfService/details": "Branch Self Service Details",
    "/survey": "Survey",
    "/waitinglist": "Waiting List",
    "/timesheetreport/employee": "Employee Timesheet Report",
    "/timesheetreport/employee/detail": "Employee Timesheet Details",
    // "/timesheetreport/students": "Students Timesheet Report",
    "/customerSupportAdmin": "Customer Support Admin",
    "/customerSupportAdmin/add": "Add Customer Support Admin",
    "/customerSupportAdmin/edit": "Edit Customer Support Admin",
    "/customerSupportAdmin/details": "Customer Support Admin Details",
    "/customerSupportUser": "Customer Support User",
    "/customerSupportUser/add": "Add Customer Support User",
    "/customerSupportUser/edit": "Edit Customer Support User",
    "/customerSupportUser/details": "Customer Support User Details",
    "/part-time-course": "Part Time Course",
    "/referrals": "Referrals",
    "/creditanddiscounts": "Credit & Discounts",  
    "/refunds": "Refunds",
    "/branchSelfService/changePassword": "Change Password",
    "/trialClassRequest": "Trial Class Request",
    "/trialClassRequest/details": "Trial Class Request Details",
    // "/camp": "Camp List",
    // "/camp/add": "Add Camp",
    "/priceAndPlans": "Price List",
    "/priceAndPlans/add": "Add Price"
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return {
      key: url,
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
  });

  const breadcrumbItems = extraBreadcrumbItems;
  const titleFromPath = breadcrumbNameMap[`/${pathSnippets.join("/")}`] || "";
  const contentCSS: CSSProperties = {
    transition: "ease-in-out",
    transitionDuration: "0.2s",
    minHeight: "100vh",
  };

  const chatBadge = useSelector((state: IRootState) =>
    state.badgeReducer.get("chatBadge")
  );
  const notificationBadge = useSelector((state: IRootState) =>
    state.badgeReducer.get("notificationBadge")
  );

  return (
    <Layout hasSider={true}>
      <Sidebar
        open={isSidebarOpen}
        onClose={setSidebarOpen}
        onMobile={onMobile}
      />
      <Layout>
        <Content style={{ ...contentCSS }}>
          <Header id="header">
            {isOnMobile() && (
              <AButton
                type="text"
                icon={
                  isSidebarOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() => setSidebarOpen(!isSidebarOpen)}
                style={{
                  width: "3rem",
                  height: "3rem",
                  color: tokens.COLOR_PRIMARY,
                }}
              />
            )}
            <Space>
              <div
                style={{
                  padding: "1.5rem 0.5rem",
                }}
              >
                <img
                  alt="logo"
                  src={sloganlogo}
                  width={!isSidebarOpen ? "0" : "48px"}
                  onClick={() => navigate("/")}
                />
              </div>
            </Space>
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
              <Badge
                count={
                  notifications.filter(
                    (notification: NotificationProps) =>
                      (notification?.model_type === "Group Chat" ||
                        notification?.model_type === "Chat") &&
                      notification.seen === 0
                  ).length + chatBadge
                }
              >
                <Avatar
                  className="notification"
                  shape="circle"
                  size={32}
                  icon={<ChatIcon />}
                  onClick={() => {
                    dispatch(chatActions.makeChatBadgeNull());
                    navigate("/chat");
                  }}
                />
              </Badge>

              <Badge
                count={
                  notifications.filter(
                    (notification: NotificationProps) =>
                      notification?.model_type !== "Group Chat" &&
                      notification?.model_type !== "Chat" &&
                      notification.seen === 0
                  ).length + notificationBadge
                }
              >
                <Avatar
                  className="notification"
                  shape="circle"
                  size={32}
                  icon={<NotificationIcon />}
                  onClick={() => {
                    navigate("notifications");
                  }}
                />
              </Badge>
              <Dropdown menu={{ items }}>
                <a href=" " onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        lineHeight: "0px",
                      }}
                    >
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_USERNAME,
                          fontSize: "1rem",
                          lineHeight: "0",
                          marginBottom: "0.3rem",
                          marginLeft: "1rem",
                        }}
                      >
                        {formattedFullName}
                      </p>
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_NEUTRAL_N80,
                          fontSize: "0.8rem",
                          marginBottom: "0.3rem",
                          lineHeight: "0",
                        }}
                      >
                        {"Corporate"}
                      </p>
                      <p
                        style={{
                          textTransform: "capitalize",
                          color: tokens.COLOR_NEUTRAL_N80,
                          fontSize: "0.8rem",
                          lineHeight: "0",
                        }}
                      >
                        {formattedRoleName}
                      </p>
                    </div>
                    <Avatar shape="circle" size={32} icon={<UserOutlined />} />
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Space>
          </Header>
          <div
            style={{
              paddingLeft: "16px",
            }}
          >
            <ATitle
              level={3}
              content={titleFromPath}
              style={{ color: tokens.COLOR_NEUTRAL_N500 }}
            />
          </div>
          <div
            style={{
              paddingLeft: "16px",
            }}
          >
            <Breadcrumb separator=">" items={breadcrumbItems} />
          </div>

          <Content
            style={{
              margin: onMobile ? "0 1rem 3rem 1rem" : "0 1rem 2rem 1rem",
            }}
          >
            {children}
          </Content>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default MainLayout;
