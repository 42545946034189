const actions = {
    SAVE_PRICES: "SAVE_PRICES",
    SAVE_PRICES_SUCCESS: "SAVE_PRICES_SUCCESS",
    SAVE_PRICES_FAILED: "SAVE_PRICES_FAILED",
    FETCH_PRICES: "FETCH_PRICES",
    FETCH_PRICES_SUCCESS: "FETCH_PRICES_SUCCESS",
    FETCH_PRICES_FAILED: "FETCH_PRICES_FAILED",

    savePrice: (
        data: any,
        event: string,
        resolve: (value: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.SAVE_PRICES,
        data,
        event,
        resolve,
        reject,
    }),

    fetchPrices: (
        resolve: (token: string) => void,
        reject: (value: string) => void
      ) => ({
        type: actions.FETCH_PRICES,
        resolve,
        reject,
      }),
}

export default actions;
