import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "../pages/login/store";
import { evaluationPlacementsReducer } from "pages/evaluationTestRequests/store";
import { coursesReducer } from "pages/course/store";
import { instructorsReducer } from "pages/instructor/store";

import { branchReducer } from "pages/branch/store";
import { timeSlotReducer } from "pages/timeSlot/store";
import { batchReducer } from "pages/batch/store";
import { myKidsReducer } from "pages/home/ParentDashboard/store";
import { childReducer } from "pages/home/ChildDashboard/store";
import { branchAdminReducer } from "pages/home/AdminDashboard/store";
import { studentsReducer } from "pages/students/store";
import { attendanceReducer } from "pages/attendance/store";
import { changeRequestReducer, changeRequestSaga } from "pages/admin/store";
import { leaveReducer } from "pages/LeaveRequests/store";
import { notificationReducer } from "pages/notifications/store";
import { conversationListReducer } from "pages/chat/store/conversationlist";
import { messageListReducer } from "pages/chat/store/message";
import { assignmentReducer } from "pages/assignment/store";
import { newEventsReducer } from "pages/News&Events/store";
import { branchAdminsReducer } from "pages/branchAdmin/store";
import { superAdminsReducer } from "pages/superadmin/store";
import { couponReducer } from "pages/coupons/store";
import { settingsReducer } from "pages/settings/store";
import { studentPaymentStatusReducer } from "pages/billing&payments/store";
import { removeChildReducer } from "pages/removeChild/store";
import { allChildReducer } from "pages/child/store";
import { logsReducer } from "pages/logs/store";
import { badgeReducer } from "layout/store";
import { branchAssociatesReducer } from "../pages/branchAssociate/store/index";
import { branchSelfServicesReducer } from "pages/branchSelfService/store";
import { fetchQuestionsReducer } from "pages/surveyform/store";
import { fetchSurveyAnswersReducer } from "pages/survey/store";
import { trialClassesReducer } from "pages/trialClassRequest/store";
import { appointmentReducer } from "pages/bookAnAppointment/store";
import { pickupPersonsReducer } from "pages/pickupPerson/store";
import { mailingAddressReducer } from "pages/enrollment/store";
import { employeeAttendanceReducer } from "pages/employeeAttendance/store";
import { pendingStudentsReducer } from "pages/pendingStudents/store";
import { userDetailsReducer } from "pages/website/store";
import { childDetailsReducer } from "pages/waitingList/store"
import { timesheetReducer } from "pages/timeSheetReport/store";
import { customerSupportAdminsReducer } from "pages/customerSupportAdmin/store";
import { customerSupportUsersReducer } from "pages/customerSupportUser/store";
import { parentAndChildAssociationReducer } from "pages/parentAndChildAssociation/store";
import { referralsReducer } from "pages/referral/store";
import { refundsReducer } from "pages/fundManagement/store";
import { creditAndDiscountsReducer } from "pages/creditAndDiscounts/store";
import { campsReducer } from "pages/camp/store";
import { pricesReducer } from "pages/priceAndPlans/store";

const appReducer = combineReducers({
  ...userReducer,
  ...evaluationPlacementsReducer,
  ...coursesReducer,
  ...instructorsReducer,
  ...branchReducer,
  ...timeSlotReducer,
  ...batchReducer,
  ...myKidsReducer,
  ...childReducer,
  ...branchAdminReducer,
  ...branchAssociatesReducer,
  ...studentsReducer,
  ...attendanceReducer,
  ...changeRequestReducer,
  ...leaveReducer,
  ...notificationReducer,
  ...conversationListReducer,
  ...messageListReducer,
  ...assignmentReducer,
  ...newEventsReducer,
  ...branchAdminsReducer,
  ...superAdminsReducer,
  ...couponReducer,
  ...settingsReducer,
  ...studentPaymentStatusReducer,
  ...removeChildReducer,
  ...allChildReducer,
  ...logsReducer,
  ...badgeReducer,
  ...branchSelfServicesReducer,
  ...fetchQuestionsReducer,
  ...fetchSurveyAnswersReducer,
  ...trialClassesReducer,
  ...appointmentReducer,
  ...pickupPersonsReducer,
  ...mailingAddressReducer,
  ...employeeAttendanceReducer,
  ...pendingStudentsReducer,
  ...userDetailsReducer,
  ...childDetailsReducer,
  ...timesheetReducer,
  ...customerSupportAdminsReducer,
  ...customerSupportUsersReducer,
  ...parentAndChildAssociationReducer,
  ...referralsReducer,
  ...refundsReducer,
  ...creditAndDiscountsReducer,
  ...campsReducer,
  ...pricesReducer,
});

// const rootReducer = (
// 	state:CombinedState<{
// 			userReducer: any;
// 		  }>
// ) => {
// 	if (action.type === "LOGOUT") {
// 		return appReducer(undefined, action);
// 	}
// 	return appReducer(state);
// };

export default (
  state:
    | CombinedState<{
        timeSlotReducer: any;
        branchReducer: any;
        evaluationPlacementsReducer: any;
        userReducer: any;
        instructorsReducer: any;
        coursesReducer: any;
        batchReducer: any;
        myKidsReducer: any;
        childReducer: any;
        branchAdminReducer: any;
        branchAssociatesReducer: any;
        studentsReducer: any;
        attendanceReducer: any;
        changeRequestReducer: any;
        leaveReducer: any;
        notificationReducer: any;
        conversationListReducer: any;
        messageListReducer: any;
        assignmentReducer: any;
        newEventsReducer: any;
        branchAdminsReducer: any;
        superAdminsReducer: any;
        couponReducer: any;
        settingsReducer: any;
        studentPaymentStatusReducer: any;
        removeChildReducer: any;
        allChildReducer: any;
        logsReducer: any;
        badgeReducer: any;
        branchSelfServicesReducer: any;
        fetchQuestionsReducer: any;
        fetchSurveyAnswersReducer: any;
        trialClassesReducer: any;
        appointmentReducer: any;
        pickupPersonsReducer :any;
        mailingAddressReducer: any;
        employeeAttendanceReducer: any;
        pendingStudentsReducer: any;
        userDetailsReducer: any;
        childDetailsReducer: any;
        timesheetReducer: any;
        customerSupportAdminsReducer: any;
        customerSupportUsersReducer: any;
        parentAndChildAssociationReducer: any;
        referralsReducer: any;
        refundsReducer: any;
        creditAndDiscountsReducer: any;
        campsReducer: any;
        pricesReducer: any;
      }>
    | undefined,
  action: AnyAction
) => appReducer(action.type === "LOGOUT" ? undefined : state, action);
