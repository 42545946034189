const actions = {
  FETCH_EVALUATION_FORM: "FETCH_EVALUATION_FORM",
  FETCH_EVALUATION_FORM_SUCCESS: "FETCH_EVALUATION_FORM_SUCCESS",
  FETCH_EVALUATION_FORM_FAILED: "FETCH_EVALUATION_FORM_FAILED",
  UPDATE_EVALUATION_FORM: "UPDATE_EVALUATION_FORM",
  UPDATE_EVALUATION_FORM_SUCCESS: "UPDATE_EVALUATION_FORM_SUCCESS",
  UPDATE_EVALUATION_FORM_FAILED: "UPDATE_EVALUATION_FORM_FAILED",

  updateForm: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_EVALUATION_FORM,
    data,
    resolve,
    reject,
  }),

  fetchEvaluationForm: (
    // page: number,
    // pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void,
    user_id?: any,
  ) => ({
    type: actions.FETCH_EVALUATION_FORM,
    // page,
    // pageSize,
    resolve,
    reject,
    user_id,
  }),
};

export default actions;
