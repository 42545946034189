import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface EvaluationPlacementFormsReturnType {
  [x: string]: string;
}

const fetchEvaluationForm = takeLatest(
  actions.FETCH_EVALUATION_FORM,
  function* (
    action: AnyAction
  ): Generator<Effect, void, EvaluationPlacementFormsReturnType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator(`api/evaluationTest?user_id=${action?.user_id}`, action),
        false
      );
      yield put({
        type: actions.FETCH_EVALUATION_FORM_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_EVALUATION_FORM_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

const updateEvaluationForm = takeLatest(
  actions.UPDATE_EVALUATION_FORM,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/evaluationTest/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_EVALUATION_FORM_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.UPDATE_EVALUATION_FORM_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([fetchEvaluationForm, updateEvaluationForm]);
}
