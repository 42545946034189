import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import changeRequestactions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchChangeRequest = takeLatest(
  changeRequestactions.FETCH_CHANGEREQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/enrollmentChangeReq/${action.id}`
      );
      yield put({
        type: changeRequestactions.FETCH_CHANGEREQUEST_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchChangeRequests = takeLatest(
  changeRequestactions.FETCH_CHANGEREQUESTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, "/api/enrollmentChangeReq");
      yield put({
        type: changeRequestactions.FETCH_CHANGEREQUESTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const createChangeRequest = takeLatest(
  changeRequestactions.SAVE_CHANGEREQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.post,
        `api/enrollmentChangeReq`,
        action.data,
        null
      );
      yield put({
        type: changeRequestactions.SAVE_CHANGEREQUEST_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const updateChangeRequest = takeLatest(
  changeRequestactions.UPDATE_CHANGEREQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/enrollmentChangeReq/${action.data.id}`,
        action.data
      );
      yield put({
        type: changeRequestactions.UPDATE_CHANGEREQUEST_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const fetchEnrollments = takeLatest(
  changeRequestactions.FETCH_ENROLLMENTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator(`/api/enrollment?user_id=${action?.user_id}`, action)
      );
      yield put({
        type: changeRequestactions.FETCH_ENROLLMENTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const fetchEmergencyConatactPerson = takeLatest(
  changeRequestactions.FETCH_EMERGENCY_CONTACT_PERSON,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/emergencyContactPerson/${action.id}`
      );
      yield put({
        type: changeRequestactions.FETCH_EMERGENCY_CONTACT_PERSON_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchMedicalCondition = takeLatest(
  changeRequestactions.FETCH_MEDICAL_CONDITION,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/medicalCondition/${action.id}`
      );
      yield put({
        type: changeRequestactions.FETCH_MEDICAL_CONDITION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchPhysicianInformation = takeLatest(
  changeRequestactions.FETCH_PHYSICIAN_INFORMATION,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/physicianInformation/${action.id}`
      );
      yield put({
        type: changeRequestactions.FETCH_PHYSICIAN_INFORMATION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

const fetchFoodPreferences = takeLatest(
  changeRequestactions.FETCH_FOOD_PREFERENCES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.get,
        `/api/foodPreference/${action.id}`
      );
      yield put({
        type: changeRequestactions.FETCH_FOOD_PREFERENCES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, error);
    }
  }
);

export default function* saga() {
  yield all([
    fetchChangeRequest,
    fetchChangeRequests,
    updateChangeRequest,
    createChangeRequest,
    fetchEnrollments,
    fetchEmergencyConatactPerson,
    fetchMedicalCondition,
    fetchPhysicianInformation,
    fetchFoodPreferences,
  ]);
}
