import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchPrices = takeLatest(
  actions.FETCH_PRICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.get, urlGenerator("api/priceAndPlans", action));
      yield put({
        type: actions.FETCH_PRICES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const savePrice = takeLatest(
  actions.SAVE_PRICES,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      if (action.event === "ADD") {
        const response = yield call(api.post, "api/priceAndPlans", action.data, null);
        action.data.id = response.id;
        yield put({
          type: actions.SAVE_PRICES_SUCCESS,
          payload: response,
          event: action.event,
        });
        yield call(action.resolve, "success");
      }

      if (action.event === "EDIT") {
        yield call(api.put, `api/priceAndPlans/${action.data.id}`, action.data);
        yield put({
          type: actions.SAVE_PRICES_SUCCESS,
          payload: action.data,
          event: action.event,
        });
        yield call(action.resolve, "success");
      }
    } catch (error) {
      console.error("Error saving price:", error);
      yield call(action.reject, "failed");  // Reject on error
    }
  }
);

export default function* saga() {
  yield all([
    savePrice,
    fetchPrices
  ]);
}